import React, { useEffect, useState } from "react";
import "./styles.css";
import { useForm } from "react-hook-form";
import { checkSteps, checkText, showSteps } from "./helper-methods";

import { useGlobalContext } from "../../context/context";
import axios from "axios";
import { Slide, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Loading } from "../../components";

function CreateAccount() {
  const [completedStep, setCompletedStep] = useState(0);
  const [loading, setloading] = useState(false);
  const [accountNumber, setAccNum] = useState("");
  const [wait, setwait] = useState(false);
  const [almost, setAlmost] = useState(false);
  const [activeBackground, setActiveBackground] = useState(1);
  const navigate = useNavigate();
  const { selectedCountry, setallCountries, baseUrl, getAllWithdrawalss } =
    useGlobalContext();

  const { handleSubmit, getValues, register } = useForm();

  const currencyDetails = {
    symbol: "",
    name: "",
    flag: "",
  };

  for (const currencyCode in selectedCountry[0]?.currencies) {
    if (
      Object.hasOwnProperty.call(selectedCountry[0]?.currencies, currencyCode)
    ) {
      const currency = selectedCountry[0]?.currencies[currencyCode];

      currencyDetails.symbol = currency?.symbol;
      currencyDetails.name = selectedCountry[0]?.name.common;
      currencyDetails.flag = selectedCountry[0]?.flags.png;
    }
  }

  const validateRegistration = async (data) => {
    setloading(true);
    data.country = currencyDetails;
    data.accountNumber = accountNumber;

    setTimeout(() => {
      setwait(true);
    }, 9000);

    setTimeout(() => {
      setAlmost(true);
    }, 18000);
    axios
      .post(`${baseUrl}auth/register`, data)
      .then((data) => {
        setloading(false);
        setwait(false);
        setAlmost(false);
        toast.success("Registration complete");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      })
      .catch((erro) => {
        setloading(false);
        setwait(false);
        setAlmost(false);
        if (erro?.response?.data?.keyPattern?.email === 1) {
          toast.error("User Already Exist", {
            transition: Slide,
            theme: "dark",
          });
        }
      });
  };

  const handleRegister = (data) => {
    checkSteps(
      getValues,
      setCompletedStep,
      completedStep,
      validateRegistration,
      data
    );
  };

  useEffect(() => {
    getAllWithdrawalss();

    setAccNum(
      `${Math.floor(100000000 + Math.random() * 900000000)}${
        Math.floor(11 + Math.random() * 10) % 10
      }`
    );

    axios
      .get(`https://restcountries.com/v3.1/all`)
      .then((res) => {
        setallCountries(res.data);
      })
      .catch((err) => {});
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (activeBackground === 3) {
        setActiveBackground(1);
      } else {
        setActiveBackground(activeBackground + 1);
      }
    }, 5000);
  }, [activeBackground]);


  return (
    <section
      className={`login_wrapper register_wrapper ${
        activeBackground === 2
          ? "active_background_one"
          : activeBackground === 3
          ? "active_background_two"
          : ""
      }`}
    >
      <div className="register_image_sect"></div>
      <div className="register_form_sect">
        <h1>Sign Up</h1>
        <p className="register_info_text">Create An Account To Get Started</p>
        <form
          className="register_form"
          onSubmit={handleSubmit((data) => handleRegister(data))}
        >
          {showSteps(completedStep, register)}
          <button className={`btn enable_btn`}>
            {checkText(completedStep, loading, wait, almost)}
          </button>
        </form>
      </div>
    </section>
  );
}

export default Loading(CreateAccount);
